import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { NgrxFormsModule } from 'ngrx-forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { EffectsModule } from '@ngrx/effects';

import { TranslationModule } from '@libs/translation/translation.module';

import { UserProfileComponent } from './user-profile.component';
import { EditSectionComponent } from './components/edit-section/edit-section.component';
import { UserInfoSectionComponent } from './components/info-section/user-info-section.component';
import { ProfileNavComponent } from './components/profile-nav/profile-nav.component';
import { AccountComponent } from './components/views/account/account.component';
import { AvailabilityComponent } from './components/views/availability/availability.component';
import { DocumentsComponent } from './components/views/documents/documents.component';
import { SettingsComponent } from './components/views/settings/settings.component';

import { getReducers as getUserProfileReducers } from './state/user-profile.reducers';
import { AccountState, initialState as accountInitialState, USER_PROFILE_FEATURE_KEY } from './state/user-profile-state.model';
import { UserProfileEffects } from './state/user-profile.effects';
import { UserProfileService } from './services/user-profile.service';
import { UserProfileResolver } from './user-profile.resolver';
import { TitleSelectionComponent } from './components/title-selection/title-selection.component';
import { AvatarUploadComponent } from './components/avatar-upload/avatar-upload.component';
import { FileUploadModule } from 'ng2-file-upload';
import { ProfileStatusComponent } from './components/profile-status/profile-status.component';
import { DocumentAreaComponent } from './components/views/documents/document-area/document-area.component';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import { NewsLetterSubscriptionComponent } from '@libs/user-profile/components/news-letter-subscription/news-letter-subscription.component';
import { TechnicianSkillsComponent } from './components/views/technician-skills/technician-skills.component';
import { TechnicianSelfIdentificationComponent } from './components/views/technician-self-id/technician-self-id.component';
import { PhoneNumberInputComponent } from '@libs/user-profile/components/phone-number-input/phone-number-input.component';
import { DocumentSummariesComponent } from './components/views/documents/document-summaries/document-summaries.component';
import {
  ExperienceConfirmationRequestComponent
} from '@libs/user-profile/components/technician-request/experience-confirmation-request/experience-confirmation-request.component';
import {
  PerformanceFeedbackRequestComponent
} from './components/technician-request/performance-feedback-request/performance-feedback-request.component';
import { BackofficeAccountManagementComponent } from './components/views/backoffice-account-management/backoffice-account-management.component';
import { ChangeUserEmailComponent } from './components/views/backoffice-account-management/components/change-user-email/change-user-email.component';
import {
  SetTemporaryPasswordComponent
} from '@libs/user-profile/components/views/backoffice-account-management/components/set-temporary-password/set-temporary-password.component';
import {
  ExperienceConfirmationComponent
} from '@libs/user-profile/components/views/backoffice-account-management/components/experience-confirmation/experience-confirmation.component';
import {
  SetTechnicianReferenceNumberComponent
} from '@libs/user-profile/components/views/backoffice-account-management/components/set-technician-reference-number/set-technician-reference-number.component';
import {
  PerformanceFeedbackComponent
} from './components/views/backoffice-account-management/components/performance-feedback/performance-feedback.component';
import {
  BackofficePerformanceFeedbackManagementComponent
} from './components/views/backoffice-performance-feedback-management/backoffice-performance-feedback-management.component';
import { AutomaticReplyComponent } from '@libs/user-profile/components/automatic-reply/automatic-reply.component';
import { AutomaticReplyFormComponent } from '@libs/user-profile/components/automatic-reply/automatic-reply-form/automatic-reply-form.component';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { JoinPipe } from '@libs/shared/pipes/join/join.pipe';
import { CountryModule } from '@libs/common-ui/country/country.module';
import { SharedModule } from '@platform/shared/shared.module';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

defineLocale('engb', enGbLocale); // This one is setting the week start of the calendar to Monday;

export function getInitialState(): Partial<AccountState> {
  return {
    ...accountInitialState
  };
}

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    TypeaheadModule,
    NgrxFormsModule,
    StoreModule.forFeature(USER_PROFILE_FEATURE_KEY, getUserProfileReducers(), {
      initialState: getInitialState
    }),
    EffectsModule.forFeature([UserProfileEffects]),
    TranslationModule,
    CommonUiModule,
    SharedModule,
    TooltipModule.forRoot(),
    NgxMaskDirective,
    NgxMaskPipe,
    FileUploadModule,
    JoinPipe,
    CountryModule,
    SharedModule
  ],
  declarations: [
    UserProfileComponent,
    ProfileStatusComponent,
    UserInfoSectionComponent,
    EditSectionComponent,
    ProfileNavComponent,
    AccountComponent,
    AvailabilityComponent,
    DocumentsComponent,
    SettingsComponent,
    TitleSelectionComponent,
    PhoneNumberInputComponent,
    AvatarUploadComponent,
    DocumentAreaComponent,
    DocumentSummariesComponent,
    NewsLetterSubscriptionComponent,
    BackofficeAccountManagementComponent,
    TechnicianSkillsComponent,
    TechnicianSelfIdentificationComponent,
    ExperienceConfirmationRequestComponent,
    ChangeUserEmailComponent,
    SetTemporaryPasswordComponent,
    SetTechnicianReferenceNumberComponent,
    ExperienceConfirmationComponent,
    PerformanceFeedbackComponent,
    SetTechnicianReferenceNumberComponent,
    PerformanceFeedbackRequestComponent,
    BackofficePerformanceFeedbackManagementComponent,
    AutomaticReplyComponent,
    AutomaticReplyFormComponent
  ],
  providers: [UserProfileService, UserProfileResolver],
  exports: [UserProfileComponent]
})
export class UserProfileModule {}
