<div class="contract-details-container">
    <staffnow-go-back-button [currentPath]="breadcrumbs"></staffnow-go-back-button>
    @if(isSettingInitialValues) {
    <div class="loader-wrapper">
        <staff-loader [customClass]="'splash-screen-loader'"></staff-loader>
    </div>
    } @else {
    <div class="create-offer-container form-container">
        <section class="create-offer-main">
            <div class="profile-container">
                <form [formGroup]="offerForm" class="form-body">
                    <div class="form-group highlight row create-offer-header">
                        <div class="form-group offer-type-selector">
                            <label for="type"> {{ 'Type*' | translate }} </label><br />
                            <button id="type" disabled class="btn btn-outline-primary btn-highlighted mr-4">
                                <span class="material-icons-outlined btn-icon"> check_circle </span>
                                {{ 'PUBLIC' | translate }}
                            </button>
                        </div>
                        <div class="form-group offer-priority-selector">
                            <label for="priority-level">{{ 'Priority level*' | translate }}</label>
                            <simple-select
                                id="priority-level"
                                [getLabel]="getLabelFunction"
                                [items]="['HIGH', 'MEDIUM', 'LOW']"
                                formControlName="priority"
                            >
                            </simple-select>
                            <span class="user-guide">
                                <small class="text-muted">
                                    {{ 'Only visible for Aviation Companies.' | translate }}
                                </small>
                            </span>
                        </div>
                        @if (facilityProfile.allowTcnApplicationsToggle) {
                        <div class="form-group highlight row w-100 mb-0 mt-0">
                            <div class="custom-control custom-checkbox">
                                <input
                                    [checked]="!offerForm.get('allowTcnApplications').value"
                                    (change)="onTcnApplicationToggleChange($event)"
                                    class="custom-control-input"
                                    id="allowTcnApplications"
                                    type="checkbox"
                                    [disabled]="isEdit"
                                />
                                <label class="custom-control-label" for="allowTcnApplications">
                                    {{ 'CREATE_OFFER.TCN_LABEL' | translate }}
                                </label>
                            </div>
                        </div>
                        }
                    </div>
                    <div class="form-group row">
                        <div class="form-group col" style="padding-right: 26px">
                            <div class="custom-control custom-checkbox">
                                <input
                                    id="custom-reference-number"
                                    type="checkbox"
                                    class="custom-control-input"
                                    name="custom-reference-number"
                                    formControlName="useCustomReference"
                                    (change)="toggleCustomReference($event)"
                                />
                                <label for="custom-reference-number" class="custom-control-label">
                                    {{ 'Custom Reference' | translate }}
                                    <small class="text-muted optional">
                                        {{ '(optional)' | translate }}
                                    </small>
                                </label>
                            </div>
                            <input
                                type="text"
                                class="form-control"
                                autocomplete="off"
                                minlength="6"
                                maxlength="12"
                                [placeholder]="'Type 6 to 12 characters to identify your job opening' | translate"
                                oninput="this.value = this.value.toUpperCase()"
                                formControlName="refNumber"
                            />
                        </div>
                        <div class="form-group col">
                            <label for="startDate">{{ 'Pick a start date*' | translate }}</label>
                            <date-picker-single
                                id="startDate"
                                [minDate]="rangePickerMinDate.toString()"
                                [placeholder]="'Pick a start date' | translate"
                                formControlName="startDate"
                            ></date-picker-single>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="title">{{ 'Title' | translate }}</label>
                            <input
                                id="title"
                                type="text"
                                class="form-control"
                                autocomplete="off"
                                minlength="6"
                                maxlength="60"
                                [placeholder]="'Type 6 to 60 characters to give a name to your job opening' | translate"
                                formControlName="title"
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="location">{{ 'Location*' | translate }}</label>
                            <ng-select
                                id="location"
                                bindLabel="name"
                                placeholder="Select location"
                                [ngModelOptions]="{ standalone: true }"
                                [closeOnSelect]="true"
                                [clearSearchOnAdd]="true"
                                [searchable]="true"
                                [disabled]="!offersAreHandledCentrally"
                                [items]="locationList"
                                [(ngModel)]="selectedLocation"
                                (change)="writeLocationIdToForm()"
                            ></ng-select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="technician-type">{{ 'Technician Type*' | translate }}</label>
                            <select
                                id="technician-type"
                                class="custom-select"
                                name="type"
                                [ngClass]="{
                                    'is-invalid': offerForm.controls.ameType.dirty && offerForm.controls.ameType.invalid
                                }"
                                formControlName="ameType"
                                (change)="getAmeLicenseOptions($event)"
                            >
                                <option [ngValue]="null">
                                    {{ 'Select an option' | translate }}
                                </option>
                                <option *ngFor="let option of ameNomenclatureOptions" [value]="option.name">
                                    {{ option.name }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col" *ngIf="!isSingletonNomenclature">
                            <label for="level">
                                {{ 'Technician Level*' | translate }}
                            </label>
                            <select
                                class="custom-select"
                                id="level"
                                [ngClass]="{
                                    'is-invalid':
                                        offerForm.controls.ameTitleId.dirty && offerForm.controls.ameTitleId.invalid
                                }"
                                formControlName="ameTitleId"
                            >
                                <option [ngValue]="null">
                                    {{ 'Select an option' | translate }}
                                </option>
                                @for (license of ameLicenseTypeOptions; track license.id) {
                                <option [ngValue]="license.id">
                                    {{ license.name }}
                                </option>
                                }
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="form-group col aircrafts">
                            <label for="airplanes">{{ 'Type of aircraft**' | translate }}</label>
                            <multiple-options-select
                                id="airplanes"
                                placeholder="Select aircraft"
                                [elements]="aircraftList"
                                [selectedElements]="selectedAircraftList"
                                (onChange)="updateSelectedAircrafts($event)"
                            ></multiple-options-select>
                        </div>
                        <div class="col">
                            <label for="other-airplanes">
                                {{ 'Other aircraft**' | translate }}
                            </label>
                            <input
                                id="other-airplanes"
                                type="text"
                                class="form-control"
                                autocomplete="off"
                                maxlength="120"
                                formControlName="otherAirplanes"
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="experience">{{ 'Min Experience*' | translate }}</label>
                            <div class="input-group">
                                <input
                                    id="experience"
                                    class="form-control"
                                    type="number"
                                    min="1"
                                    max="60"
                                    autocomplete="off"
                                    placeholder="{{ 'Enter min experience' | translate }}"
                                    formControlName="minExperience"
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text" id="years">{{ 'years' | translate }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col">
                            <div>
                                <label for="vacancies">{{ 'Number of Technicians*' | translate }}</label>
                                <input
                                    class="form-control"
                                    type="number"
                                    id="vacancies"
                                    min="1"
                                    max="999"
                                    autocomplete="off"
                                    placeholder="{{ 'Enter number of technicians' | translate }}"
                                    formControlName="vacancies"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="form-group col">
                            @if (facilityProfile.allowsHideRateInJobOffers) {
                            <div class="custom-control custom-checkbox">
                                <input
                                    class="custom-control-input"
                                    id="hasHiddenRate"
                                    type="checkbox"
                                    formControlName="hasHiddenRate"
                                />
                                <label class="custom-control-label" for="hasHiddenRate">
                                    {{ 'CREATE_OFFER.HIDDING_SALARY_LABEL' | translate }}
                                </label>
                            </div>
                            }
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="netSalary">{{ 'Net salary per month*' | translate }}</label>
                            <div class="input-group">
                                <input
                                    id="netSalary"
                                    class="form-control"
                                    type="number"
                                    min="1"
                                    max="9999"
                                    autocomplete="off"
                                    placeholder="{{ 'Enter net salary per month' | translate }}"
                                    formControlName="netSalaryPerMonth"
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        {{ currencySymbol }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col">&nbsp;</div>
                    </div>
                    <div class="form-group row">
                        <div class="form-group col-4 pattern-view">
                            <label for="work-pattern">{{ 'Work Pattern*' | translate }}</label>
                            <div class="custom-controls-stacked" id="work-pattern">
                                @for (workingPattern of fixedWorkingPatterns; track index;let index= $index) {
                                <div class="custom-control custom-radio">
                                    <input
                                        id="{{ 'working-pattern' + workingPattern.daysOn + workingPattern.daysOff }}"
                                        type="radio"
                                        name="pattern"
                                        class="custom-control-input"
                                        [checked]="isWorkingPatternSelected(workingPattern)"
                                        (change)="setWorkingPattern(workingPattern)"
                                    />
                                    <label
                                        for="{{ 'working-pattern' + workingPattern.daysOn + workingPattern.daysOff }}"
                                        class="custom-control-label"
                                    >
                                        {{
                                            workingPattern.daysOn + ' days on - ' + workingPattern.daysOff + ' days off'
                                                | translate
                                        }}
                                    </label>
                                </div>
                                }
                                <div class="custom-control custom-radio">
                                    <input
                                        id="custom-pattern-radio"
                                        type="radio"
                                        name="pattern"
                                        class="custom-control-input"
                                        [checked]="isCustomWorkingPatternSelected"
                                        (change)="enableCustomWorkingPattern()"
                                    />
                                    <label for="custom-pattern-radio" class="custom-control-label">
                                        {{ 'Custom' | translate }}
                                    </label>
                                </div>
                                <div class="form-row mt-2">
                                    <div class="form-group col-6 padded" *ngIf="isCustomWorkingPatternSelected">
                                        <label for="work-days">{{ 'Work Days*' | translate }}</label>
                                        <input
                                            class="form-control"
                                            type="number"
                                            id="work-days"
                                            [tooltip]="'Work days' | translate"
                                            name="ondays"
                                            min="1"
                                            max="99"
                                            maxlength="2"
                                            [ngClass]="{
                                                'is-invalid': offerForm.controls.workDaysOn.invalid
                                            }"
                                            formControlName="workDaysOn"
                                        />
                                    </div>
                                    <div class="form-group col-6" *ngIf="isCustomWorkingPatternSelected">
                                        <label>
                                            {{ 'Off Days*' | translate }}
                                            <input
                                                class="form-control"
                                                type="number"
                                                id="off-days"
                                                [tooltip]="'Off days' | translate"
                                                name="offdays"
                                                min="1"
                                                max="99"
                                                maxlength="2"
                                                [ngClass]="{
                                                    'is-invalid': offerForm.controls.workDaysOff.invalid
                                                }"
                                                formControlName="workDaysOff"
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-8">
                            <label for="short-presentation">{{ 'Job description*' | translate }} </label>
                            <textarea
                                id="short-presentation"
                                class="form-control"
                                name="presentation"
                                cols="30"
                                rows="5"
                                [attr.maxlength]="PRESENTATION_MAX_LENGTH"
                                formControlName="shortPresentation"
                            >
                            </textarea>
                        </div>
                    </div>
                    <div formArrayName="additionalBenefits" class="form-group row mb-3">
                        <collapsible heading="Benefits" style="width: 100%" highlight="true">
                            <div style="padding: 0 20px">
                                @for (benefit of benefits; track benefit.type) {
                                <div class="benefit">
                                    <div class="wrapper">
                                        <input
                                            type="checkbox"
                                            id="{{ benefit.type }}"
                                            [checked]="benefit?.isSelected"
                                            (change)="benefitSelected($event, benefit)"
                                        />
                                        <label for="{{ benefit.type }}">{{
                                            benefitLabel(benefit.type) | translate
                                        }}</label>
                                        @if(benefitIsSelected(benefit)) {
                                        <div class="input-group detail">
                                            @if(requiresAmount(benefit)) {
                                            <input
                                                class="form-control"
                                                type="number"
                                                min="1"
                                                [defaultValue]="benefit.amount"
                                                max="999"
                                                style="max-width: 200px"
                                                autocomplete="off"
                                                placeholder="{{ 'Enter amount (Required)' | translate }}"
                                                [ngClass]="{
                                                    'is-invalid': benefit.isSelected && !isBenefitValid(benefit)
                                                }"
                                                (change)="addAmountToBenefit($event, benefit)"
                                            />
                                            <div class="input-group-append">
                                                <span class="input-group-text"> {{ currencySymbol }} </span>
                                            </div>
                                            }
                                            <input
                                                class="form-control"
                                                type="text"
                                                [defaultValue]="benefit.description || ''"
                                                autocomplete="off"
                                                maxlength="100"
                                                placeholder="{{ 'Write a description (Optional)' | translate }}"
                                                (change)="addDescriptionToBenefit($event, benefit)"
                                            />
                                        </div>
                                        }
                                    </div>
                                </div>
                                }
                            </div>
                        </collapsible>
                    </div>
                    <div class="form-row justify-content-center create-offer-form-actions">
                        <general-button
                            (onClick)="cancelCreation()"
                            backgroundStyle="outline"
                            class="mr-5"
                            text="CANCEL"
                        ></general-button>
                        <general-button
                            (onClick)="handleFormSubmit()"
                            [isDisabled]="!offerForm.valid || !areBenefitsValid()"
                            [isLoading]="isSubmitting"
                            text="CONFIRM"
                        ></general-button>
                    </div>
                </form>
            </div>
        </section>

        <section class="create-offer-user">
            <div class="user-container">
                <div class="form-head" style="text-align: center">
                    <h1 class="h1">
                        <strong>Selected Technicians</strong>
                    </h1>
                    @if(noSelectedTechnicians()) {
                    <span>No technicians selected</span>
                    } @for( technician of selectedTechnicians | keyvalue; track technician.key) {
                    <div class="technician-item">
                        @if(technician.value._links?.getProfilePicture) {
                        <div
                            class="profile-pic"
                            [ngStyle]="{
                                'background-image': 'url(\'' + technician.value._links.getProfilePicture.href + '\')'
                            }"
                        ></div>
                        <div class="profile-pic">
                            <i class="material-icons"> person </i>
                        </div>
                        }
                        <div class="name">
                            <h4 class="h4">
                                {{ technician.value._embedded.profile.firstName }}
                                {{ technician.value._embedded.profile.lastName }}
                            </h4>
                        </div>
                        <div
                            class="clear"
                            (click)="handleRemoveFromSelectedTechnicians(technician.value)"
                            (keydown.enter)="handleRemoveFromSelectedTechnicians(technician.value)"
                        >
                            <i class="material-icons"> clear </i>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </section>
    </div>
    }
</div>
