import { createAction, props } from '@ngrx/store';

import { AccountFormModel } from '../components/views/account/account-form.model';
import { DocumentArea, DocumentType, StaffnowDocument } from '../model/documents.model';
import { Link } from '@libs/shared/bms-common/rest/resource.model';
import { UserProfileType } from '@libs/shared/models/user-profile.type';
import { SubscriptionItem } from '@libs/shared/models/technician-subscription.model';
import { Skill } from '@libs/shared/models/skills.model';
import { TechnicianSelfIdentificationDto } from '@libs/shared/models/technician-profile.model';
import { TechnicianPerformanceFeedback } from '../components/views/backoffice-performance-feedback-management/technician-performance-feedback.model';
import { BlocklistDto } from '@libs/user-profile/state/user-profile-state.model';

export const SetProfileUrl = createAction('[User Profile] Set Profile Url', props<{ profileUrl: string }>());

export const GetNotificationSubscriptions = createAction(
  '[User Profile] Get notification subscriptions',
  props<{ subscriptionsUrl: string }>()
);

export const NotificationSubscriptionsLoaded = createAction(
  '[User Profile] Notification subscriptions loaded',
  props<{ subscriptions: SubscriptionItem[] }>()
);

export const FailedToGetNotificationSubscriptions = createAction(
  '[User Profile] Failed to get notification subscriptions'
);

export const SubmitNotificationSubscriptions = createAction(
  '[User Profile] Submit notification subscriptions list',
  props<{ subscriptionsUrl: string; subscriptions: SubscriptionItem[] }>()
);

export const ToggleTechnicianToMatchingOffersSubscription = createAction(
  '[User Profile] ToggleTechnicianToMatchingOffersSubscription',
  props<{ url: string; subscriptionsUrl: string; isSubscribing: boolean }>()
);

export const ToggleTechnicianWeeklyJoSummarySubscription = createAction(
  '[User Profile] ToggleTechnicianWeeklyJoSummarySubscription',
  props<{ url: string; isSubscribing: boolean }>()
);

export const SuccessfullyToggledTechnicianWeeklyJoSummarySubscription = createAction(
  '[User Profile] SuccessfullyToggledTechnicianWeeklyJoSummarySubscription'
);

export const FailedToToggleTechnicianWeeklyJoSummarySubscription = createAction(
  '[User Profile] FailedToToggleTechnicianWeeklyJoSummarySubscription'
);

export const FailedToSubmitNotificationSubscriptions = createAction(
  '[User Profile] Failed to submit notification subscriptions list'
);

export const SuccessfullySubmitSubscriptionsList = createAction(
  '[User Profile] Successfully submitted subscriptions list'
);

export const GetBlocklist = createAction('[User Profile] Get blocklist', props<{ url: string }>());

export const BlocklistLoaded = createAction('[User Profile] Blocklist loaded', props<{ payload: BlocklistDto }>());

export const SubmitBlocklist = createAction(
  '[User Profile] Submit blocklist',
  props<{ targetUrl: string; facilities: any }>()
);

export const FailedToGetBlocklist = createAction('[User Profile] Failed to get blocklist');

export const FailedToSubmitBlocklist = createAction('[User Profile] Failed to submit blocklist');

export const InitiateDocumentDownload = createAction(
  '[User Profile] Initiate document download',
  props<{ documentDownloadUrl: string }>()
);

export const InitiateAllDocumentsDownload = createAction(
  '[User Profile] Initiate all documents download',
  props<{ documentDownloadUrl: string }>()
);

export const FinishAllDocumentsDownload = createAction('[User Profile] Finish all documents download');

export const FailedToDownloadDocument = createAction('[User Profile] Failed to get token for download file');

export const SuccessfulDocumentDownload = createAction('[User Profile] Successful document download');
export const SetDocumentExpirationDate = createAction(
  '[User Profile] Set document expiration date',
  props<{ document: StaffnowDocument; selectedDate: string }>()
);
export const SetDocumentPossession = createAction(
  '[User Profile] SetDocumentPossession',
  props<{ documentAreaId: number; document: DocumentType }>()
);
export const DocumentPossessionSuccessfullySet = createAction(
  '[User Profile] DocumentPossessionSuccessfullySet',
  props<{ documentAreaId: number; document: DocumentType }>()
);
export const ResetLoading = createAction('[User Profile] Reset loading state');

export const FetchUserAvailability = createAction(
  '[User Profile] Fetch user availability',
  props<{ availabilityUrl: string }>()
);

export const UserAvailabilityLoaded = createAction(
  '[User Profile] User availability loaded',
  props<{ payload: any }>()
);

export const FailedToFetchUserAvailability = createAction('[User Profile] Failed to fetch user availability');

export const FileSuccessfullyDeleted = createAction(
  '[User Profile] FileSuccessfullyDeleted',
  props<{ context: string; documentAreaId: number; documentContextsEmptyAfterRemoval: string[] }>()
);

export const FailedToDeleteFile = createAction('[User Profile] Failed to delete file');

export const UpdateUserProfile = createAction('[User Profile] Update user profile');

export const FailedToLoadUser = createAction('[User Profile] Failed to load user');

export const RemoveDocument = createAction(
  '[User Profile] Initiate request for deleting document',
  props<{ payload: any; documentAreaId: number; documentContextsEmptyAfterRemoval: string[] }>()
);

export const SubmitProfileChanges = createAction(
  '[User Profile] Update user profile on the backend',
  props<{ formValue: AccountFormModel; profileSelfLink: Link }>()
);

export const UserProfileUpdated = createAction(
  '[User Profile] User profile updated',
  props<{ payload: UserProfileType }>()
);

//TODO(SN-977) model for the payload
export const InitiateUpdateRequest = createAction(
  '[User Profile] Initiate request for user update',
  props<{ payload: any }>()
);

export const InitiateChangeStaffieStatus = createAction(
  '[User Profile] Initiate request for staffie status update',
  props<{
    payload: any;
    changeStaffieStatusLink: Link;
    profileSelfLink: Link;
  }>()
);

export const ChangeApprovedDocumentStatus = createAction(
  '[User Profile] Change already approved document status',
  props<{ fileUrl: string }>()
);

export const ChangeDocumentStatus = createAction('[User Profile] Change document status', props<{ payload: any }>());

export const FailedToChangeDocumentStatus = createAction('[User Profile] Failed to change document status');

export const UpdateProfileStatus = createAction(
  '[User Profile] Update profile status',
  props<{ url: string; data: any }>()
);

export const FailedToChangeProfileStatus = createAction('[User Profile] Failed to change Profile status');

export const ResetUserAccountForm = createAction('[User Profile] Reset user-profile account state');

export const ResetUserProfileState = createAction('[User Profile] Reset user-profile state');

export const UpdateAuthorizedUserPicture = createAction(
  '[User Profile] Update logged user picture',
  props<{ updatedPicUrl: string }>()
);

export const UpdateAuthorizedUserInfo = createAction(
  '[User Profile] Update the authorized user info',
  props<{ payload: any }>()
);

export const AddTechnicianUserToAgency = createAction(
  '[User Profile] Add technician to agency',
  props<{ payload: any }>()
);

export const TechnicianUserToAgencyAdded = createAction('[User Profile] Technician added to agency');

export const RemoveTechnicianUserFromAgency = createAction(
  '[User Profile] Remove technician from agency',
  props<{ payload: any }>()
);

export const TechnicianUserRemovedFromAgency = createAction('[User Profile] Technician removed from agency');

export const GetDocumentAreas = createAction('[User Profile] Get Document areas', props<{ technicianUuid: string }>());

export const SuccessGetDocumentAreas = createAction(
  '[User Profile] Success Get Document areas',
  props<{ documentAreas: Array<DocumentArea> }>()
);

export const ErrorGetDocumentAreas = createAction('[User Profile] Error Get Document areas');

export const ResetDocumentAreas = createAction('[User Profile] Reset Document areas');

export const UpdateMroFavoriteTechnicians = createAction(
  '[User Profile] Mro updates list of favorite technicians',
  props<{ technicianUuid: string; url: string }>()
);

export const UpdateBackofficeNotes = createAction(
  '[User Profile] Update backoffice notes',
  props<{ notes: string; url: string }>()
);

export const ChangeNewsletterSubscription = createAction(
  '[User Profile] Change Newsletter Subscription',
  props<{ url: string }>()
);

export const UpdateNewsletterSubscription = createAction(
  '[User Profile] Update Newsletter Subscription',
  props<{ isSubscribed: boolean }>()
);

export const ChangeUserEmail = createAction(
  '[User Profile] Change user email',
  props<{ url: string; email: string }>()
);

export const FailedToChangeUserEmail = createAction('[User Profile] Change user email failed');

export const SetTemporaryPassword = createAction(
  '[User Profile] Set temporary password',
  props<{ url: string; password: string }>()
);

export const FailedToSetTemporaryPassword = createAction('[User Profile] Set temporary password failed');

export const SetTechnicianRefNumber = createAction(
  '[User Profile] SetTechnicianRefNumber',
  props<{ url: string; refNumber: string }>()
);

export const FailedToSetTechnicianRefNumber = createAction('[User Profile] FailedToSetTechnicianRefNumber');

export const GrantCrossRegionAccess = createAction(
  '[User Profile] Grant Cross Region Access',
  props<{ url: string; payload: any }>()
);

export const GetListOfSkills = createAction('[User Profile] GetListOfSkills');

export const UpdateTechnicianSkills = createAction(
  '[User Profile] UpdateTechnicianSkills',
  props<{ skillsIds: number[]; otherSkills: string; hasSetSkills: boolean; url: string }>()
);

export const ListOfSkillsLoaded = createAction('[User Profile] ListOfSkillsLoaded', props<{ listOfSkills: Skill[] }>());

export const UpdateTechnicianSelfIdentification = createAction(
  '[User Profile] UpdateTechnicianSelfIdentification',
  props<{ technicianSelfIdentification: TechnicianSelfIdentificationDto; url: string }>()
);

export const FinishedUpdatingTechnicianSelfIdentification = createAction(
  '[User Profile] FinishedUpdatingTechnicianSelfIdentification'
);

export const LoadTechnicianPerformanceFeedbacks = createAction(
  '[User Profile] LoadTechnicianPerformanceFeedbacks',
  props<{ url: string }>()
);

export const TechnicianPerformanceFeedbacksLoaded = createAction(
  '[User Profile] TechnicianPerformanceFeedbacksLoaded',
  props<{ feedbacks: TechnicianPerformanceFeedback[] }>()
);

export const UserProfileActions = {
  SetProfileUrl,
  UpdateUserProfile,
  FailedToLoadUser,
  SubmitProfileChanges,
  UserProfileUpdated,
  InitiateUpdateRequest,
  InitiateChangeStaffieStatus,
  ChangeDocumentStatus,
  FailedToChangeDocumentStatus,
  RemoveDocument,
  FailedToDeleteFile,
  UpdateProfileStatus,
  FailedToChangeProfileStatus,
  FetchUserAvailability,
  UserAvailabilityLoaded,
  FailedToFetchUserAvailability,
  InitiateDocumentDownload,
  SuccessfulDocumentDownload,
  FailedToDownloadDocument,
  ResetLoading,
  ChangeApprovedDocumentStatus,
  ResetUserAccountForm,
  ResetUserProfileState,
  UpdateAuthorizedUserPicture,
  GetBlocklist,
  BlocklistLoaded,
  FailedToGetBlocklist,
  SubmitBlocklist,
  FailedToSubmitBlocklist,
  UpdateAuthorizedUserInfo,
  GetNotificationSubscriptions,
  NotificationSubscriptionsLoaded,
  FailedToGetNotificationSubscriptions,
  SubmitNotificationSubscriptions,
  SuccessfullySubmitSubscriptionsList,
  FailedToSubmitNotificationSubscriptions,
  AddTechnicianUserToAgency: AddTechnicianUserToAgency,
  TechnicianUserToAgencyAdded: TechnicianUserToAgencyAdded,
  RemoveTechnicianUserFromAgency: RemoveTechnicianUserFromAgency,
  TechnicianUserRemovedFromAgency: TechnicianUserRemovedFromAgency,
  GetDocumentAreas,
  SuccessGetDocumentAreas,
  ErrorGetDocumentAreas,
  UpdateMroFavoriteTechnicians: UpdateMroFavoriteTechnicians,
  UpdateNewsletterSubscription
};
