<div class="mobile-avatar-status">
    <staffnow-avatar-upload></staffnow-avatar-upload>
    <staffnow-profile-status *ngIf="canEdit && isTechnicianProfile()"></staffnow-profile-status>
</div>
<ng-template #readOnlyProfile>
    <div *ngIf="userProfileIsLoaded()" class="profile-container read-only-profile">
        <div class="profile-info">
            <label for="fullName">
                {{ 'GENERAL.USER_ACCOUNT.FULL_NAME' | translate }}
            </label>
            <p id="fullName">
                {{ accountFormValue.controls.firstName.value + ' ' + accountFormValue.controls.lastName.value }}
            </p>
        </div>
        <div class="profile-info" *ngIf="userProfile?.country">
            <label for="country">
                {{ 'GENERAL.USER_ACCOUNT.COUNTRY' | translate }}
            </label>
            <p id="country">
                {{ userProfile.country }}
            </p>
        </div>
        <div class="profile-info" *ngIf="userProfile?.state">
            <label for="state">
                {{ 'GENERAL.USER_ACCOUNT.STATE' | translate }}
            </label>
            <p id="state">
                {{ userProfile.state }}
            </p>
        </div>
        <div class="profile-info" *ngIf="userProfile?.street">
            <label for="street">
                {{ 'GENERAL.USER_ACCOUNT.STREET' | translate }}
            </label>
            <p id="street">
                {{ userProfile.street }}
            </p>
        </div>
        <div class="profile-info" *ngIf="userProfile?.city">
            <label for="city">
                {{ 'GENERAL.USER_ACCOUNT.CITY' | translate }}
            </label>
            <p id="city">
                {{ userProfile.city }}
            </p>
        </div>
        <div class="profile-info" *ngIf="userProfile?.zipCode">
            <label for="zipCode">
                {{ 'GENERAL.USER_ACCOUNT.ZIPCODE' | translate }}
            </label>
            <p id="zipCode">
                {{ userProfile.zipCode }}
            </p>
        </div>
        <div class="profile-info" *ngIf="userProfile?.presentation">
            <label for="presentation">
                {{ 'GENERAL.USER_ACCOUNT.TECHNICIAN.SHORT_PRESENTATION' | translate }}
            </label>
            <p id="presentation">
                {{ userProfile.presentation }}
            </p>
        </div>
        <div class="profile-info" *ngIf="userProfile?.userPhone">
            <label for="phoneNumberReadOnly">
                {{ 'GENERAL.USER_ACCOUNT.CONTACT_NUMBER' | translate }}
            </label>
            <p id="phoneNumberReadOnly">
                {{ contactNumberReadOnly }}
            </p>
        </div>
        <div class="mobile">
            <div class="profile-info" *ngIf="userProfile?.ameTitles?.length > 0">
                <label for="titles">
                    {{ ('HELP.TITLE_REQUIREMENTS_ITEM_MENU.TITLE' | translate) + 's' }}
                </label>
                <div id="titles">
                    <p *ngFor="let title of userProfile?.ameTitles">
                        {{ title.type + ' ' + (title.license ? title.license : '') | translate }}
                    </p>
                </div>
            </div>
            <div class="profile-info" *ngIf="userProfile?.experience">
                <label for="years-of-experience">
                    {{ 'GENERAL.USER_ACCOUNT.EXPERIENCE' | translate }}
                </label>
                <p id="years-of-experience">
                    {{ 'GENERAL.USER_ACCOUNT.EXPERIENCE_YEARS' | translate : { count: userProfile.experience } }}
                </p>
            </div>
            <div class="profile-info" *ngIf="selectedAircraftList.length > 0">
                <label for="aircraft">
                    {{ 'GENERAL.USER_ACCOUNT.AIRCRAFT' | translate }}
                </label>
                <p id="aircraft">
                    {{ selectedAircraftList | selectedAircraft }}
                </p>
            </div>
        </div>
        <div class="profile-info" *ngIf="selectedLanguages.length > 0">
            <label for="language-list">
                {{ 'GENERAL.USER_ACCOUNT.LANGUAGES' | translate }}
            </label>
            <p id="language-list">
                {{ selectedLanguages | join }}
            </p>
        </div>
        <div class="profile-info" *ngIf="preferredWorkShifts.length > 0">
            <label for="work-shift-preference">
                {{ 'GENERAL.USER_ACCOUNT.WORK_SHIFT_PREFERENCE' | translate }}
            </label>
            <p id="work-shift-preference">
                {{ preferredWorkShifts | join }}
            </p>
        </div>
        <div class="profile-info" *ngIf="preferredContractType">
            <label for="contract-type-preference">
                {{ 'GENERAL.USER_ACCOUNT.CONTRACT_TYPE_PREFERENCE' | translate }}
            </label>
            <p id="contract-type-preference">
                {{ preferredContractType }}
            </p>
        </div>
        <div class="profile-info" *ngIf="preferredPayRangeMin > 0 && preferredPayRangeMax > 0">
            <label for="pay-range-preference">
                {{ 'GENERAL.USER_ACCOUNT.PAY_RANGE_PREFERENCE_PER_HOUR' | translate }}
            </label>
            <p id="pay-range-preference">{{ preferredPayRangeMin }}$ - {{ preferredPayRangeMax }}$</p>
        </div>
    </div>
</ng-template>
<ng-container *ngIf="canEdit; else readOnlyProfile">
    <div
        class="profile-form profile-container"
        [ngClass]="{
            'input-disabled': isUserDeleted
        }"
    >
        <form *ngIf="accountForm" autocomplete="off" novalidate [ngrxFormState]="accountForm">
            <fieldset>
                <legend>Personal Information</legend>
                <div class="form-group">
                    <label for="firstName">{{ 'GENERAL.USER_ACCOUNT.FULL_NAME' | translate }}*</label>
                    <div class="row">
                        <div
                            class="col-sm-12 col-lg-6"
                            *ngIf="accountFormValue?.controls?.firstName"
                            [ngClass]="{
                                'missing-data': isAttributeMissing('firstName')
                            }"
                        >
                            <span
                                *ngIf="isAttributeMissing('firstName')"
                                class="first-name"
                                (click)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                                (keydown.enter)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                                [tooltip]="
                                    isAttributeMissing('firstName') && isPlatformWeb
                                        ? USER_PROFILE_TOOLTIPS.missingData
                                        : ('' | translate)
                                "
                            >
                                warning
                            </span>
                            <input
                                #firstNameInput
                                [ngClass]="{ 'warning-form-control': isAttributeMissing('firstName') }"
                                class="form-control"
                                id="firstName"
                                type="text"
                                [placeholder]="'Given Names' | translate"
                                [ngrxFormControlState]="accountFormValue.controls.firstName"
                            />
                        </div>
                        <div
                            class="col-sm-12 col-lg-6"
                            *ngIf="accountFormValue?.controls?.lastName"
                            [ngClass]="{
                                'missing-data': isAttributeMissing('lastName')
                            }"
                        >
                            <span
                                [tooltip]="
                                    isAttributeMissing('lastName') && isPlatformWeb
                                        ? USER_PROFILE_TOOLTIPS.missingData
                                        : ('' | translate)
                                "
                                *ngIf="isAttributeMissing('lastName')"
                                class="last-name"
                                (click)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                                (keydown.enter)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                            >
                                warning
                            </span>
                            <input
                                [ngClass]="{ 'warning-form-control': isAttributeMissing('lastName') }"
                                class="form-control"
                                id="lastName"
                                type="text"
                                [placeholder]="'Last Name' | translate"
                                [ngrxFormControlState]="accountFormValue.controls.lastName"
                            />
                        </div>
                    </div>
                </div>
                @if (!isEurope) {
                <div class="col-grid-3">
                    <div class="form-group" *ngIf="accountFormValue.controls.street">
                        <label for="home-street">
                            {{ 'GENERAL.USER_ACCOUNT.STREET.EDIT_LABEL' | translate }}
                        </label>
                        <div [ngClass]="{ 'missing-data': isAttributeMissing('street') }">
                            <span
                                *ngIf="isAttributeMissing('street')"
                                [tooltip]="isPlatformWeb ? USER_PROFILE_TOOLTIPS.missingData : ''"
                                class="alert-icon"
                                (click)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                                (keydown.enter)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                            >
                                warning
                            </span>
                            <input
                                [ngClass]="{ 'warning-form-control': isAttributeMissing('street') }"
                                class="form-control"
                                id="home-street"
                                type="text"
                                [ngrxFormControlState]="accountFormValue.controls.street"
                            />
                        </div>
                    </div>
                    <div class="form-group" *ngIf="accountFormValue.controls.city">
                        <label for="home-city">
                            {{ 'GENERAL.USER_ACCOUNT.CITY.EDIT_LABEL' | translate }}
                        </label>
                        <div [ngClass]="{ 'missing-data': isAttributeMissing('city') }">
                            <span
                                *ngIf="isAttributeMissing('city')"
                                [tooltip]="isPlatformWeb ? USER_PROFILE_TOOLTIPS.missingData : ''"
                                class="alert-icon"
                                (click)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                                (keydown.enter)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                            >
                                warning
                            </span>
                            <input
                                [ngClass]="{ 'warning-form-control': isAttributeMissing('city') }"
                                class="form-control"
                                id="home-city"
                                type="text"
                                [ngrxFormControlState]="accountFormValue.controls.city"
                            />
                        </div>
                    </div>
                    <div class="form-group" *ngIf="accountFormValue.controls.zipCode">
                        <label for="home-zipCode">
                            {{ 'GENERAL.USER_ACCOUNT.ZIPCODE.EDIT_LABEL' | translate }}
                        </label>
                        <div [ngClass]="{ 'missing-data': isAttributeMissing('zipCode') }">
                            <span
                                *ngIf="isAttributeMissing('zipCode')"
                                [tooltip]="isPlatformWeb ? USER_PROFILE_TOOLTIPS.missingData : ''"
                                class="alert-icon"
                                (click)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                                (keydown.enter)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                            >
                                warning
                            </span>
                            <input
                                [ngClass]="{ 'warning-form-control': isAttributeMissing('zipCode') }"
                                class="form-control"
                                id="home-zipCode"
                                type="text"
                                [ngrxFormControlState]="accountFormValue.controls.zipCode"
                                [maxLength]="apiRegion == AppRegionEnum.USA ? 5 : 10"
                            />
                        </div>
                    </div>
                </div>
                }
                <div class="col-grid-3">
                    @if(isUserProfileTechnicianRole() && accountFormValue.controls.country) {
                    <div class="form-group">
                        <label for="home-country">
                            {{ 'GENERAL.USER_ACCOUNT.COUNTRY.EDIT_LABEL' | translate }}
                        </label>

                        <div [ngClass]="{ 'missing-data': isAttributeMissing('country') }">
                            <span
                                *ngIf="isAttributeMissing('country')"
                                [tooltip]="isPlatformWeb ? USER_PROFILE_TOOLTIPS.missingData : ''"
                                class="alert-icon"
                                (click)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                                (keydown.enter)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                            >
                                warning
                            </span>
                            <country-selector
                                [ngClass]="{ 'warning-form-control-country': isAttributeMissing('country') }"
                                id="home-country"
                                [ngrxFormControlState]="accountFormValue.controls.country"
                                (countryChanged)="onSelectedCountryChange()"
                            ></country-selector>
                        </div>
                    </div>
                    }
                    <div class="form-group" *ngIf="accountFormValue.controls.state">
                        <label for="home-state">
                            {{ 'GENERAL.USER_ACCOUNT.STATE.EDIT_LABEL' | translate }}
                        </label>
                        <div
                            [ngClass]="{
                                'missing-data':
                                    isAttributeMissing('state') && !accountFormValue.controls.state.isDisabled
                            }"
                        >
                            <span
                                *ngIf="isAttributeMissing('state') && !accountFormValue.controls.state.isDisabled"
                                [tooltip]="isPlatformWeb ? USER_PROFILE_TOOLTIPS.missingData : ''"
                                class="alert-icon"
                                (click)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                                (keydown.enter)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                            >
                                warning
                            </span>
                            <state-selector
                                [ngClass]="{ 'warning-form-control-state': isAttributeMissing('state') }"
                                id="home-state"
                                [ngrxFormControlState]="accountFormValue.controls.state"
                                [country]="accountFormValue.controls.country.value"
                            ></state-selector>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="accountFormValue.controls.userPhone">
                        <label for="userPhone">
                            {{ 'GENERAL.USER_ACCOUNT.CONTACT_NUMBER' | translate
                            }}{{ isPhoneNumberRequired() ? '*' : '' }}
                        </label>
                        <div
                            [ngClass]="{
                                'missing-data': isAttributeMissing('userPhone')
                            }"
                        >
                            <div class="input-container">
                                <span
                                    [tooltip]="
                                        isAttributeMissing('userPhone') && isPlatformWeb
                                            ? USER_PROFILE_TOOLTIPS.missingData
                                            : ('' | translate)
                                    "
                                    *ngIf="isAttributeMissing('userPhone')"
                                    class="user-phone"
                                    (click)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                                    (keydown.enter)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                                >
                                    warning
                                </span>
                                <phone-number-input
                                    [ngClass]="{ 'warning-form-control-phone-number': isAttributeMissing('userPhone') }"
                                    id="userPhone"
                                    [countries]="countries"
                                    [ngrxFormControlState]="accountFormValue.controls.userPhone"
                                ></phone-number-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-grid-2">
                    <div *ngIf="canEditRoles()" class="form-group">
                        <label for="role">{{ 'GENERAL.ROLE' | translate }}</label>
                        <select
                            id="role"
                            class="custom-select"
                            name="role"
                            [ngrxFormControlState]="accountFormValue.controls.role"
                        >
                            <option *ngFor="let role of getUserRoleList()" value="{{ role.serverName }}">
                                {{ role.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group" *ngIf="accountFormValue?.controls?.position">
                        <label for="position">{{ 'GENERAL.USER_ACCOUNT.POSITION' | translate }}*</label>
                        <input
                            class="form-control"
                            id="position"
                            type="text"
                            [placeholder]="'Position' | translate"
                            [ngrxFormControlState]="accountFormValue.controls.position"
                        />
                    </div>
                </div>
            </fieldset>
            @if (this.isProffesionalInformation()) {
            <fieldset class="margin-top">
                <legend>Professional Information</legend>
                <div class="form-group" *ngIf="accountFormValue.controls.ameTitles">
                    <div class="d-flex justify-content-between align-items-center titles-help-container">
                        <div class="position-titles-flex">
                            <span
                                class="material-icons-outlined info-button help default-cursor"
                                [tooltip]="isPlatformWeb ? ('SYSTEM.INFO.TECHNICIAN_TITLE_TOOLTIP' | translate) : ''"
                                (click)="onTooltipClick('SYSTEM.INFO.TECHNICIAN_TITLE_TOOLTIP')"
                                (keydown.enter)="onTooltipClick('SYSTEM.INFO.TECHNICIAN_TITLE_TOOLTIP')"
                            >
                                help_outline
                            </span>
                            <label for="ame-title-wrapper">
                                {{ ('HELP.TITLE_REQUIREMENTS_ITEM_MENU.TITLE' | translate) + 's' }} *
                            </label>
                        </div>
                        <a
                            [href]=""
                            (click)="openTitlesRequirementsSectionTitlesRequirements()"
                            class="d-flex justify-content-between align-items-center titles-help"
                            *ngIf="isTechnician"
                        >
                            <div class="d-flex justify-content-center align-items-center">
                                <span class="material-icons-outlined info-button help"> help_outline </span>
                                <span>{{ 'HELP.TITLE_REQUIREMENTS_ITEM_MENU.LABEL' | translate }}</span>
                            </div>
                        </a>
                    </div>
                    <div id="ame-title-wrapper">
                        <ng-container *ngIf="ameNomenclature.length > 0">
                            <div
                                class="ame-title-row"
                                *ngFor="
                                    let titleGroup of accountFormValue.controls.ameTitles.controls;
                                    let i = index;
                                    trackBy: trackById
                                "
                            >
                                <title-selection
                                    [ameNomenclature]="ameNomenclature"
                                    [ngrxFormControlState]="titleGroup"
                                ></title-selection>
                                <general-button
                                    throttle="false"
                                    (onClick)="removeAmeTitle(i)"
                                    backgroundStyle="outline"
                                    icon="delete"
                                    color="danger"
                                    size="small"
                                ></general-button>
                            </div>
                            <div class="input-container">
                                <general-button
                                    [ngClass]="{ 'warning-form-control-ame-title': isAttributeMissing('ameTitle') }"
                                    throttle="false"
                                    (onClick)="addNewAmeTitle()"
                                    backgroundStyle="outline"
                                    size="small"
                                    text="{{ 'ADD ' + ('HELP.TITLE_REQUIREMENTS_ITEM_MENU.TITLE' | translate) }}"
                                ></general-button>
                                <span
                                    [tooltip]="
                                        isAttributeMissing('ameTitle') && isPlatformWeb
                                            ? USER_PROFILE_TOOLTIPS.missingData
                                            : ('' | translate)
                                    "
                                    *ngIf="isAttributeMissing('ameTitle')"
                                    class="alert-icon"
                                    (click)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                                    (keydown.enter)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                                >
                                    warning
                                </span>
                            </div>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="accountFormValue.controls.ameTitles.isInvalid">
                        <p class="text-danger" *ngIf="accountFormValue.controls.ameTitles.errors.minLength">
                            {{
                                'At least one ' +
                                    ('HELP.TITLE_REQUIREMENTS_ITEM_MENU.TITLE' | translate | lowercase) +
                                    ' is required' | translate
                            }}
                        </p>
                        <p class="text-danger" *ngIf="!accountFormValue.controls.ameTitles.errors.minLength">
                            {{
                                ('HELP.TITLE_REQUIREMENTS_ITEM_MENU.TITLE' | translate) +
                                    's must be complete and duplicated entries are not allowed' | translate
                            }}
                        </p>
                    </ng-container>
                </div>
                <div class="old-titles" *ngIf="isAdmin && userProfile.ameTitlesOld">
                    <label>{{
                        'Old ' + ('HELP.TITLE_REQUIREMENTS_ITEM_MENU.TITLE' | translate | lowercase) + 's: ' | translate
                    }}</label>
                    <technician-title-list [titleList]="userProfile.ameTitlesOld"></technician-title-list>
                </div>
                <div class="col-grid-2">
                    <div class="form-group" *ngIf="accountFormValue.controls.airplanes">
                        <label for="airplanes">
                            {{ 'GENERAL.USER_ACCOUNT.TYPE_OF_AIRCRAFT_WITH_RESPECTIVE_EXPERIENCE' | translate }}
                        </label>
                        <div
                            [tooltip]="
                                isAttributeMissing('airplanes') && isPlatformWeb
                                    ? USER_PROFILE_TOOLTIPS.missingData
                                    : ('' | translate)
                            "
                            [ngClass]="{
                                'missing-data': isAttributeMissing('airplanes')
                            }"
                        >
                            <multiple-options-select
                                id="airplanes"
                                placeholder="{{ 'GENERAL.USER_ACCOUNT.SELECT_AIRCRAFT' | translate }}"
                                [elements]="aircraftList"
                                [selectedElements]="selectedAircraftList"
                                (onChange)="setListOfItems($event, 'airplanes')"
                            ></multiple-options-select>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="accountFormValue.controls.otherAirplanes">
                        <label for="otherAircraft">{{
                            'GENERAL.USER_ACCOUNT.OTHER_TYPES_OF_AIRCRAFT' | translate
                        }}</label>
                        <div
                            [tooltip]="
                                isAttributeMissing('otherAirplanes') && isPlatformWeb
                                    ? USER_PROFILE_TOOLTIPS.missingData
                                    : ('' | translate)
                            "
                            [ngClass]="{
                                'missing-data': isAttributeMissing('otherAirplanes')
                            }"
                        >
                            <span
                                *ngIf="isAttributeMissing('otherAirplanes')"
                                class="alert-icon"
                                (click)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                                (keydown.enter)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                            >
                                warning
                            </span>
                            <input
                                class="form-control"
                                id="otherAircraft"
                                type="text"
                                maxlength="150"
                                [placeholder]="'GENERAL.USER_ACCOUNT.OTHER_TYPES_OF_AIRCRAFT' | translate"
                                [ngrxFormControlState]="accountFormValue.controls.otherAirplanes"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-grid-2 mt-2">
                    <div class="input-group" *ngIf="accountFormValue.controls.experience">
                        <label for="experience">
                            {{ 'GENERAL.USER_ACCOUNT.TOTAL_EXPERIENCE_IN_AIRCRAFT_MAINTENANCE' | translate }}*
                        </label>
                        <div
                            class="experience-input-container"
                            [ngClass]="{
                                'missing-data': isAttributeMissing('experience')
                            }"
                        >
                            <span
                                [tooltip]="
                                    isAttributeMissing('experience') && isPlatformWeb
                                        ? USER_PROFILE_TOOLTIPS.missingData
                                        : ('' | translate)
                                "
                                *ngIf="isAttributeMissing('experience')"
                                class="alert-icon"
                                (click)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                                (keydown.enter)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                            >
                                warning
                            </span>
                            <input
                                [ngClass]="{ 'warning-form-control': isAttributeMissing('experience') }"
                                class="form-control"
                                id="experience"
                                type="number"
                                min="0"
                                max="60"
                                [placeholder]="'GENERAL.USER_ACCOUNT.ADD_YEARS_OF_EXPERIENCE' | translate"
                                aria-describedby="years"
                                [ngrxFormControlState]="accountFormValue.controls.experience"
                            />
                        </div>
                        <div class="input-group-append">
                            <span class="input-group-text">
                                {{ 'GENERAL.YEARS' | translate }}
                            </span>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="accountFormValue.controls.languages">
                        <label for="languages">
                            {{ 'GENERAL.USER_ACCOUNT.LANGUAGES' | translate }}
                        </label>
                        <div>
                            <multiple-options-select
                                id="languages"
                                placeholder="{{ 'GENERAL.USER_ACCOUNT.SELECT_LANGUAGES' | translate }}"
                                [searchable]="true"
                                [elements]="languageList"
                                [selectedElements]="selectedLanguages"
                                (onChange)="setListOfItems($event, 'languages')"
                            ></multiple-options-select>
                        </div>
                    </div>
                </div>
                <div class="form-group col-grid-2">
                    @if(canEditLicenses() && accountFormValue.controls.licenses) {
                    <div class="mt-2">
                        <label for="licenses">
                            {{ 'GENERAL.USER_ACCOUNT.LICENSES' | translate }}
                        </label>
                        <multiple-options-select
                            id="licenses"
                            placeholder="{{ 'GENERAL.USER_ACCOUNT.SELECT_LICENSES' | translate }}"
                            [searchable]="true"
                            [elements]="licenses"
                            [selectedElements]="selectedLicenses"
                            (onChange)="setListOfItems($event, 'licenses')"
                        ></multiple-options-select>
                    </div>
                    <div class="mt-2">
                        @if (accountFormValue.controls.licenses?.controls?.length) {
                        <div class="form-group">
                            <label for="licenseNumber">
                                {{ 'GENERAL.USER_ACCOUNT.LICENSE_NUMBER' | translate }}
                            </label>
                            <div>
                                <input
                                    class="form-control"
                                    id="licenseNumber"
                                    type="text"
                                    maxlength="128"
                                    placeholder="{{ 'GENERAL.USER_ACCOUNT.LICENSE_NUMBER' | translate }}"
                                    [ngrxFormControlState]="accountFormValue.controls.licenseNumber"
                                />
                            </div>
                        </div>
                        }
                    </div>
                    }
                </div>
                @if (isEurope) {
                <ng-template [ngTemplateOutlet]="shortPresentation"></ng-template>
                }
            </fieldset>
            }
        </form>

        <form *ngIf="accountForm" autocomplete="off" novalidate [ngrxFormState]="accountForm">
            @if (isPreferences()) {
            <fieldset>
                <legend>Preferences</legend>
                <ng-template [ngTemplateOutlet]="shortPresentation"></ng-template>
                <div class="col-grid-2">
                    <div class="form-group" *ngIf="workShiftList?.length > 0">
                        <label for="workShiftPreference">
                            {{ 'GENERAL.USER_ACCOUNT.WORK_SHIFT_PREFERENCE' | translate }}
                        </label>
                        <div>
                            <multiple-options-select
                                id="workShiftPreference"
                                placeholder="{{ 'GENERAL.USER_ACCOUNT.SELECT_WORK_SHIFT_PREFERENCE' | translate }}"
                                [searchable]="true"
                                [elements]="workShiftList"
                                [selectedElements]="preferredWorkShifts"
                                (onChange)="setListOfItems($event, 'preferredWorkShifts')"
                            ></multiple-options-select>
                        </div>
                    </div>
                    <div *ngIf="shouldShowPreferredPayRange">
                        <label for="payRangePreference">
                            {{ 'GENERAL.USER_ACCOUNT.PAY_RANGE_PREFERENCE_PER_HOUR' | translate }}
                        </label>
                        <div id="payRangePreference" class="pay-range-preference">
                            <input
                                class="form-control"
                                id="preferredPayRangeMin"
                                type="number"
                                min="0"
                                max="999"
                                [placeholder]="'GENERAL.USER_ACCOUNT.PAY_RANGE_MIN' | translate"
                                [ngrxFormControlState]="accountFormValue.controls.preferredPayRangeMin"
                            />
                            <div class="input-group-append">
                                <span class="input-group-text"> $ </span>
                            </div>
                            <input
                                class="form-control"
                                id="preferredPayRangeMax"
                                type="number"
                                min="0"
                                max="999"
                                [placeholder]="'GENERAL.USER_ACCOUNT.PAY_RANGE_MAX' | translate"
                                [ngrxFormControlState]="accountFormValue.controls.preferredPayRangeMax"
                            />
                            <div class="input-group-append">
                                <span class="input-group-text"> $ </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group dropdown col-grid-2" *ngIf="contractTypeList?.length > 0">
                    <div class="mt-4">
                        <label for="preferredContractType">
                            {{ 'GENERAL.USER_ACCOUNT.CONTRACT_TYPE_PREFERENCE' | translate }}
                        </label>
                        <select
                            id="preferredContractType"
                            name="preferredContractType"
                            class="custom-select"
                            [(ngModel)]="preferredContractType"
                            (change)="selectContractType()"
                        >
                            <option [ngValue]="null">
                                {{ 'GENERAL.USER_ACCOUNT.SELECT_CONTRACT_TYPE_PREFERENCE' | translate }}
                            </option>
                            <option *ngFor="let contractType of contractTypeList" [ngValue]="contractType.id">
                                {{ contractType.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </fieldset>
            }
            <div
                *ngIf="accountFormValue?.controls?.defaultUser && accountFormValue?.controls?.defaultUser.value != null"
                class="custom-control custom-checkbox form-group"
            >
                <input
                    id="defaultUser"
                    type="checkbox"
                    class="custom-control-input"
                    (click)="setDefaultUser($event)"
                    (keydown.enter)="setDefaultUser($event)"
                    [disabled]="accountFormValue.controls.defaultUser.value"
                    [checked]="accountFormValue.controls.defaultUser.value"
                />
                <label for="defaultUser" class="custom-control-label font-weight-bold">
                    {{ 'is Default User' | translate }}
                </label>
            </div>
            <div class="custom-control custom-checkbox form-group" *ngIf="canEditIsTcn()" id="tcn-container">
                <input
                    type="checkbox"
                    class="custom-control-input"
                    id="tcn"
                    [ngrxFormControlState]="accountFormValue.controls.isTcn"
                />
                <label for="tcn" class="custom-control-label font-weight-bold">
                    {{ 'GENERAL.USER_ACCOUNT.THIRD_COUNTRY_NATIONALS' | translate }}
                </label>
            </div>
        </form>
    </div>
    <general-button
        (onClick)="updateUserProfile()"
        [isDisabled]="!accountFormValue.isValid || !accountFormValue.isDirty || isUserDeleted"
        [isLoading]="profileIsLoading"
        class="submit-button"
        text="{{ 'GENERAL.CONFIRM_CHANGES' | translate }}"
    ></general-button>
</ng-container>

<form *ngIf="isAdmin && isTechnicianProfile()" class="backoffice-notes-form" autocomplete="off" novalidate>
    <div class="form-group">
        <label for="backoffice-notes">{{ 'GENERAL.NOTES' | translate }}</label>
        <textarea
            class="form-control"
            id="backoffice-notes"
            name="backoffice-notes"
            type="text"
            maxlength="1000"
            rows="6"
            placeholder="{{ 'GENERAL.NOTES' | translate }}..."
            [(ngModel)]="backofficeNotes"
        ></textarea>
    </div>
    <general-button
        (onClick)="updateBackofficeNotes()"
        [isDisabled]="!canSubmitBackofficeNotes"
        [isLoading]="profileIsLoading"
        class="submit-button"
        text="{{ 'GENERAL.USER_ACCOUNT.UPDATE_NOTES' | translate }}"
    ></general-button>
</form>
<ng-template #shortPresentation>
    <div class="form-group" *ngIf="accountFormValue?.controls?.presentation">
        <label for="description">
            {{ 'GENERAL.USER_ACCOUNT.TECHNICIAN.SHORT_PRESENTATION' | translate }}*
        </label>
        <div
            [ngClass]="{
                'missing-data': isAttributeMissing('presentation')
            }"
        >
            <div class="input-container">
                <span
                    [tooltip]="
                        isAttributeMissing('presentation') && isPlatformWeb
                            ? USER_PROFILE_TOOLTIPS.missingData
                            : ('' | translate)
                    "
                    *ngIf="isAttributeMissing('presentation')"
                    class="alert-icon short-presentation"
                    (click)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                    (keydown.enter)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
                >
                    warning
                </span>
                <textarea
                    [ngClass]="{ 'warning-form-control': isAttributeMissing('presentation') }"
                    class="form-control"
                    name="description"
                    id="description"
                    maxlength="250"
                    rows="6"
                    (keyup)="updatePresentationCharactersCount()"
                    (keydown)="updatePresentationCharactersCount()"
                    placeholder="{{ 'GENERAL.USER_ACCOUNT.ADD_SHORT_PRESENTATION' | translate }}"
                    [ngrxFormControlState]="accountFormValue.controls.presentation"
                >
                </textarea>
            </div>
        </div>
        <div *ngIf="hasShortPresentationChanged" class="warning-to-changes">This section changed</div>
        <small id="inputCount" class="form-text text-muted"> {{ counter }} / {{ textAreaMaxLength }} </small>
    </div>
</ng-template>
