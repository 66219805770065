import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, debounceTime, Observable, startWith, switchMap } from 'rxjs';
import { TechnicianLoaderService } from '../../../../offer-management/shared/services/technician-loader-service/technician-loader.service';
import { map, tap } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';

const DEFAULT_PAGE_SIZE = 20;

interface SimplifiedUserRepresentation {
  name: string,
  uuid: string
}

@Component({
  selector: 'technician-paginated-selector',
  templateUrl: './technician-paginated-selector.component.html',
  styleUrls: ['./technician-paginated-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    NgSelectModule,
    TranslateModule,
    CommonModule,
    FormsModule
  ],
  standalone: true
})
export class TechnicianPaginatedSelectorComponent implements OnInit {
  @Input() technicianRetrieverRel: string;
  @Input() selectedTechnicianUuid?: string;
  @Output() selectedTechnicianUuidChange?: EventEmitter<string> = new EventEmitter();
  @Input() placeholder?: string;
  technicians$: Observable<SimplifiedUserRepresentation[]>;
  search$ = new BehaviorSubject({term: '', pageSize: DEFAULT_PAGE_SIZE, page: 0, technicians: [] as SimplifiedUserRepresentation[] });
  loading: boolean = false;


  constructor(private technicianLoaderService: TechnicianLoaderService) { }

  ngOnInit(): void {
    this.technicians$ = this.search$
      .pipe(
        tap(() => this.loading = true),
        debounceTime(250),
        switchMap((searchParams) => this.technicianLoaderService.getTechnicianListPaged(this.technicianRetrieverRel, searchParams.term, searchParams.page, searchParams.pageSize)
          .pipe(
            map(technicians => [...searchParams.technicians, ...technicians.map(technician => { return { name: technician.firstName + ' ' + technician.lastName, uuid: technician.userUuid} })])
          )),
        tap(() => this.loading = false),
        startWith([])
      );
  }

  onSearch(term: string) {
    this.search$.next({
      term: term,
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      technicians: [] as SimplifiedUserRepresentation[]
    });
  }

  loadMore(technicianList: SimplifiedUserRepresentation[]) {
    const previousPageableParams = this.search$.getValue();
    this.search$.next({
      ...previousPageableParams,
      page: previousPageableParams.page + 1,
      technicians: technicianList
    });
  }

  onChange(technician: SimplifiedUserRepresentation) {
    this.selectedTechnicianUuidChange.emit(technician ? technician.uuid : '');
  }
}
