import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { DocumentsService } from '@libs/user-profile/services/documents.service';
import { getCanEditProfile, selectUserProfile } from '@libs/user-profile/state/user-profile.selectors';
import { map, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { getEmbeddedResource, getEmbeddedResourceNullsafe, getLink, hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { UserRoles, UserRolesUtils } from '@libs/shared/models/roles.enum';
import { getFilteredApiRoot, getLoggedInUserRole } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { ConversationService } from '../../../../../apps/staffnow-platform/src/app/modules/request-overview/services/conversation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { getStaticEnvironment } from '@libs/shared/bms-common/environment/environment.selector';
import { AppRegionEnum, EnvironmentState } from '@libs/shared/bms-common/environment/environment.model';
import { Link } from '@libs/shared/bms-common/rest/resource.model';

@UntilDestroy()
@Component({template: ''})
export abstract class AbstractProfileComponent implements OnInit, OnDestroy {
  protected subscriptions: Subscription = new Subscription();
  public canEdit = false;
  public isAdmin = false;
  public userProfile: any;
  public userProfileRole: string = '';
  private userProfileChangeFns = [];
  private canEditChangeFns = [];
  public loggedInRoleIsAgency: boolean = false;
  public isOwnTechnician: boolean = false;

  protected userAccessibleRegions: any[] = [];
  protected grantAccessToRegionToBackoffice: Link = null;
  protected grantAccessToRegionToMro: Link = null;
  protected getMroFacilitiesLink: Link = null;
  protected europeApiUrl: string = null;
  protected usaApiUrl: string = null;
  protected apiRegion: string = null;
  protected readonly AppRegionEnum = AppRegionEnum;

  protected constructor(
    protected store: Store<any>,
    protected documentsService: DocumentsService,
    protected activatedRoute: ActivatedRoute,
    private conversationService?: ConversationService
  ) {
    this.store
      .pipe(getFilteredApiRoot, untilDestroyed(this))
      .subscribe(apiRoot => {
        this.grantAccessToRegionToBackoffice = hasLink(
          apiRoot,
          ApiRootLinkRel.GrantAccessToRegionToBackoffice
        )
          ? getLink(apiRoot, ApiRootLinkRel.GrantAccessToRegionToBackoffice)
          : null;
        this.grantAccessToRegionToMro = hasLink(
          apiRoot,
          ApiRootLinkRel.GrantAccessToRegionToMro
        )
          ? getLink(apiRoot, ApiRootLinkRel.GrantAccessToRegionToMro)
          : null;
        this.getMroFacilitiesLink = hasLink(
          apiRoot,
          ApiRootLinkRel.GetMROFacilitiesPaged
        )
          ? getLink(apiRoot, ApiRootLinkRel.GetMROFacilitiesPaged)
          : null;
        this.apiRegion = apiRoot.apiRegion;
      });

    this.store
      .pipe(getStaticEnvironment)
      .subscribe((env: EnvironmentState) => {
        this.europeApiUrl = env.europeApiUrl;
        this.usaApiUrl = env.usaApiUrl;
      });
  }

  ngOnInit(): void {
    this.subscribeToCanEdit();
    this.subscribeToIsAdmin();
    this.subscribeToUserProfile();
    this.subscribeToIsAgency();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  protected addSub(sub: Subscription) {
    this.subscriptions.add(sub);
  }

  private subscribeToCanEdit() {
    this.subscriptions.add(
      getCanEditProfile(this.store)
        .pipe(
          tap(canEdit => (this.canEdit = canEdit)),
          tap(canEdit => this.canEditChangeFns.forEach(fn => fn(canEdit)))
        )
        .subscribe()
    );
  }

  private subscribeToIsAdmin() {
    this.subscriptions.add(
      this.activatedRoute.data.subscribe(data => {
        this.isAdmin = data.isAdmin;
      })
    );
  }

  private subscribeToIsAgency() {
    this.subscriptions.add(
      this.store
        .pipe(
          getLoggedInUserRole,
          map((r: UserRoles) => {
            if (r === 'ROLE_AGENCY') this.loggedInRoleIsAgency = true;
          })
        )
        .subscribe()
    );
  }

  private subscribeToUserProfile() {
    this.subscriptions.add(
      this.store
        .select(selectUserProfile)
        .pipe(
          tap(userProfile => {
            this.userProfile = userProfile;
            this.isOwnTechnician = !!this.userProfile.isOwnTechnician;
            this.userProfileRole = getEmbeddedResource(
              this.userProfile,
              UserProfileLinkRel.Profile
            )['role'];
            this.userAccessibleRegions = getEmbeddedResourceNullsafe<any[]>(
              this.userProfile,
              UserProfileLinkRel.AccessibleRegions
            );
          }),
          tap(userProfile =>
            this.userProfileChangeFns.forEach(fn => fn(userProfile))
          )
        )
        .subscribe()
    );
  }

  protected onUserProfileChange(fn) {
    this.userProfileChangeFns.push(fn);
  }

  protected onCanEditChange(fn) {
    this.canEditChangeFns.push(fn);
  }

  public isTechnicianProfile(): boolean {
    return UserRolesUtils.isTechnician(this.userProfileRole);
  }

  public initiateMessageWithTechnician(): void {
    this.conversationService.initiateMessageWithTechnicianBeforeOffer(
      this.userProfile
    );
  }
}
