<div *ngIf="(technicians$ | async) as technicians">
    <ng-select
        bindLabel="name"
        bindValue="uuid"
        [(ngModel)]="selectedTechnicianUuid"
        [items]="technicians"
        [loading]="loading"
        (close)="onSearch('')"
        (clear)="onSearch('')"
        (scrollToEnd)="loadMore(technicians)"
        (search)="onSearch($event.term)"
        [closeOnSelect]="true"
        [searchable]="true"
        (change)="onChange($event)"
        [placeholder]="placeholder || 'GENERAL.SEARCH_BY_NAME' | translate"
    >
    </ng-select>
</div>
