import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { AccountFormModel, initialFormState } from '../components/views/account/account-form.model';
import { TechnicianUpdateRequestPayload } from '../model/technician-request-payload.model';
import { AdminRequestPayload, AgencyRequestPayload, MroRequestPayload } from '../model/request.payload';
import { box } from 'ngrx-forms';
import { selectUserProfile } from '../state/user-profile.selectors';
import { UserProfileType } from '@libs/shared/models/user-profile.type';
import { getEmbeddedResource } from '@libs/shared/bms-common/rest/resource.utils';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { UserRoles, UserRolesUtils } from '@libs/shared/models/roles.enum';
import { dateTimeFromString } from '@libs/shared/helpers/date-utils';
import { ContractEvent, DayObject } from '@libs/shared/models/availability.model';
import { AppRegionEnum } from '@libs/shared/bms-common/environment/environment.model';
import { getCountries } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { take, tap } from 'rxjs/operators';
import { LoadCountry } from '@libs/common-ui/country/state/country-selector.actions';

@Injectable()
export class UserProfileService {
  private userProfile: UserProfileType = null;
  private userRole: UserRoles = null;

  constructor(private store: Store<any>) {
    this.store.pipe(select(selectUserProfile)).subscribe(userProfile => {
      this.userProfile = userProfile;
      this.userRole = getEmbeddedResource(this.userProfile, UserProfileLinkRel.Profile)['role'];
    });
  }

  //TODO: we cant have this kind of types (userprofiletype) when they differ that much, it makes coding so hard for absolutely 0 reasons
  public parseUserProfileToFormModel(userData: UserProfileType, apiRegion?: string): AccountFormModel {
    const embeddedProfile: any = getEmbeddedResource({ ...userData }, UserProfileLinkRel.Profile);
    const formValue = { ...initialFormState.value };

    this.setPropertyInForm('firstName', embeddedProfile, formValue);
    this.setPropertyInForm('lastName', embeddedProfile, formValue);
    this.setPropertyInForm('role', embeddedProfile, formValue);

    this.setPropertyInForm('presentation', userData, formValue);
    this.setPropertyInForm('experience', userData, formValue);
    this.setPropertyInForm('street', userData, formValue);
    this.setPropertyInForm('state', userData, formValue);
    this.setPropertyInForm('city', userData, formValue);
    this.setPropertyInForm('zipCode', userData, formValue);
    this.setPropertyInForm('otherAirplanes', userData, formValue);
    this.setPropertyInForm('airplanes', userData, formValue);
    this.setPropertyInForm('languages', userData, formValue);
    this.setPropertyInForm('position', userData, formValue);
    this.setPropertyInForm('defaultUser', userData, formValue);
    this.setPropertyInForm('backofficeNotes', userData, formValue);
    this.setPropertyInForm('isTcn', userData, formValue);
    this.setPropertyInForm('preferredWorkShifts', userData, formValue);
    this.setPropertyInForm('licenses', userData, formValue);
    this.setPropertyInForm('licenseNumber', userData, formValue);
    this.setPropertyInForm('preferredContractType', userData, formValue);
    this.setPropertyInForm('preferredPayRangeMin', userData, formValue);
    this.setPropertyInForm('preferredPayRangeMax', userData, formValue);

    if(embeddedProfile.role === UserRoles.ROLE_TECHNICIAN) {
      this.setUserCountry(userData, formValue, apiRegion);
    } else {
      delete formValue['country'];
    }
    this.setAmeTitles(userData, formValue);
    this.setUserPhone(userData, formValue);
    this.setApiRegion(apiRegion, formValue);

    return formValue;
  }

  public prepareBackendRequestPayload(formValue: AccountFormModel): any {
    if (this.userProfile) {
      if (UserRolesUtils.isMro(this.userRole)) {
        return new MroRequestPayload(formValue);
      }
      switch (this.userRole) {
        case UserRoles.ROLE_TECHNICIAN:
          return new TechnicianUpdateRequestPayload(formValue);
        case UserRoles.ROLE_MODERATOR:
        case UserRoles.ROLE_ADMIN:
          return new AdminRequestPayload(formValue);
        case UserRoles.ROLE_AGENCY:
          return new AgencyRequestPayload(formValue);
      }
    }
  }

  //TODO: refactor this method (SN-1559)
  public computeEventMap(contractEvents: ContractEvent[]): Map<string, DayObject> {
    const mappedPeriods = new Map<string, DayObject>();
    contractEvents.forEach(period => {
      const endDate = period.toDate;
      let currentDate = period.fromDate;
      let isStart: boolean = true;
      let lastObject: DayObject = null;
      while (
        (isStart || currentDate <= endDate) &&
        this.calculateEventDurationIsTwentyYears(period.fromDate, currentDate)
      ) {
        const dayObject: DayObject = {
          date: new Date(currentDate),
          isPartOfPeriod: true,
          isStartOrEndDate: isStart,
          period
        };
        lastObject = dayObject;
        mappedPeriods.set(currentDate, dayObject);
        currentDate = dateTimeFromString(currentDate).plus({ day: 1 }).toSQLDate();
        isStart = false;
      }
      if (lastObject) {
        lastObject.isStartOrEndDate = true;
      }
    });
    return mappedPeriods;
  }

  private calculateEventDurationIsTwentyYears(startDate: string, currentDate: string) {
    return dateTimeFromString(startDate).plus({ year: 20 }).toSQLDate() > currentDate;
  }

  private setPropertyInForm = (key, dataSource: any, formDestination: any) => {
    if (Object.prototype.hasOwnProperty.call(dataSource, key)) {
      formDestination[key] = dataSource[key];
    } else {
      delete formDestination[key];
    }
  };

  private setUserPhone = (userData: any, formDestination: any) => {
    if (Object.prototype.hasOwnProperty.call(userData, 'userPhone')) {
      if (userData.userPhone) {
        formDestination.userPhone = box(userData.userPhone);
      } else {
        formDestination.userPhone = box({ phoneNumber: null, countryId: null });
      }
    } else {
      delete formDestination.userPhone;
    }
  };

  private setAmeTitles = (userData: any, formDestination: any) => {
    if (userData.ameTitles) {
      formDestination.ameTitles = userData.ameTitles.map(i => box(i));
    } else {
      delete formDestination.ameTitles;
    }
  };

  private setUserCountry = (userData: UserProfileType, formDestination: AccountFormModel, region: string) => {
    let userCountryName: string = '';
    if ('country' in userData) {
      if (!userData.country && region == AppRegionEnum.USA.toString()) {
        userCountryName = 'United States';
      } else {
        userCountryName = userData.country;
      }
      this.loadUserCountryAndSetFormValue(userCountryName, formDestination);
    }
  };

  private loadUserCountryAndSetFormValue(countryName: string, formDestination: AccountFormModel) {
    this.store
      .pipe(getCountries)
      .pipe(
        take(1),
        tap(countries => {
          const currentCountry = countries?.find(country => country.name === countryName);
          if (currentCountry) {
            formDestination.country = currentCountry.name;
            this.store.dispatch(LoadCountry({ country: currentCountry }));
          } else {
            formDestination.country = '';
          }
        })
      )
      .subscribe();
  }

  private setApiRegion(userData: any, formDestination: any) {
    if (userData) {
      formDestination.apiRegion = userData;
    } else {
      delete formDestination.apiRegion;
    }
  }
}
